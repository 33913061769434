<template>
    <div id="app">
        unauthorized
    </div>
</template>

<script>

export default {
    name: "Unauthorized401",
    data() {
        return {
        };
    },
}
</script>
